<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row flex flex-col md:flex-row justify-between align-items-center">
				<div>
					<div>
						<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer ml-3">
							<span data-cy='article-heading'
								  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
									$t('previewEmail')
								}}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-9 col-lg-9 mx-auto">
					<div class=" bg-white border rounded-md p-5">
						<div class="row">
							<div class="col-md-12">
								<div class="mb-5">
									<div class="flex justify-center" v-if="blogPostPreviewImage !== ''">
										<el-image v-if="blogPostPreviewImage !== ''"
												  style="width: 843px; height: 591px"
												  :src="blogPostPreviewImage">
										</el-image>
									</div>
									<div class="space-x-4">
										<div class="blog-title">
											<p class="font-semibold text-gray-800 text-lg">{{
													blogPostContent.title
												}}</p><br>
											<small class="text-gray-400">{{ $t('published') }}: {{
													blogPostContent.published
												}}</small>
										</div>
									</div>
									<div class="flex flex-col mt-3">
										<div v-html="cleanMessage(blogPostContent.content)"></div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-if="recommendedProducts.length > 0">
								<div class="row">
									<div class="col-md-6" v-for="(item,key) in recommendedProducts"
										 :key="key">
										<blog-preview-recommend-product :item="item"/>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
		<!-- <Notification /> -->
	</div>
</template>

<script>
import blogPreviewRecommendProduct from "./blogPreviewRecommendProduct";

export default {
	name: "blogEmailTemplate",
	components: {
		blogPreviewRecommendProduct
	},
	data: function () {
		return {
			blogPostPreviewImage: '',
			blogPostContent: {},
			recommendedProducts: []
		}
	},
	methods: {
		cleanMessage(message) {
			return message
		},
		renderArrays(data) {
			const result = []
			let html = ''
			if (data) {
				if (data.length > 0) {
					data.map(v => result.push(v.name))
					result.forEach(element => {
						html += `<span class='px-2 text-gray-700 py-1 border rounded-full'>${element}</span>&nbsp;`
					})
					return html
				}
			}
		},
		getBase64File(file) {
			return new Promise((resolve, reject) => {
				var reader = new FileReader()
				reader.onload = e => {
					resolve(e.target.result)
				}
				reader.readAsDataURL(file)
			})
		},
	},
	mounted() {
		this.blogPostContent = JSON.parse(window.localStorage.getItem('__blogPreview__'))
		this.blogPostPreviewImage = window.localStorage.getItem('__blogPreviewImageP__')
		this.recommendedProducts = JSON.parse(window.localStorage.getItem('__blogAttachedProduct__'))
	},
}
</script>

<style scoped>

</style>
