<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row flex flex-col md:flex-row justify-between align-items-center">
				<div>
					<div>
						<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer ml-3">
							<span data-cy='article-heading'
								  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
									$t('preview')
								}}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-11 col-lg-11 mx-auto">
					<div class=" bg-white border rounded-md p-5">
						<div class="row">
							<div
								:class="{'col-md-12': recommendedProducts.length === 0, 'col-md-7': recommendedProducts.length > 0}">
								<blog-preview-card/>
							</div>
							<div class="col-md-5" v-if="recommendedProducts.length > 0">
								<blog-preview-recommend-product :item="item" v-for="(item,key) in recommendedProducts"
																:key="key"/>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
		<!-- <Notification /> -->
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import blogPreviewCard from "./components/blogComponent/blogPreviewCard";
import blogPreviewRecommendProduct from "./components/blogComponent/blogPreviewRecommendProduct";

export default {
	name: 'BlogPreview',
	components: {
		blogPreviewCard,
		blogPreviewRecommendProduct
	},
	computed: {
		...mapGetters({
			GET_BLOG_PREVIEW: 'portal/GET_BLOG_POST_PREVIEW',
			GET_BLOG_IMAGE: 'portal/GET_BLOG_POST_IMAGE',
		})
	},
	mounted() {
		this.blogPostContent = JSON.parse(window.localStorage.getItem('__blogPreview__'))
		this.blogPostPreviewImage = window.localStorage.getItem('__blogPreviewImageP__')
		this.recommendedProducts = JSON.parse(window.localStorage.getItem('__blogAttachedProduct__'))
	},
	data: function () {
		return {
			blogPostPreviewImage: '',
			blogPostContent: {},
			recommendedProducts: []
		}
	},
	methods: {
		cleanMessage(message) {
			return message
		},
		renderArrays(data) {
			const result = []
			let html = ''
			if (data) {
				if (data.length > 0) {
					data.map(v => result.push(v.name))
					result.forEach(element => {
						html += `<span class='px-2 text-gray-700 py-1 border rounded-full'>${element}</span>&nbsp;`
					})
					return html
				}
			}
		},
		getBase64File(file) {
			return new Promise((resolve, reject) => {
				var reader = new FileReader()
				reader.onload = e => {
					resolve(e.target.result)
				}
				reader.readAsDataURL(file)
			})
		},
	},
	beforeDestroy() {
		// window.localStorage.setItem('__blogPreview__', JSON.stringify({}))
		// window.localStorage.setItem('__blogPreviewImageP__', '')
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table th {
	// padding: 0.1rem 0.55rem !important;
	color: #000000 !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-select {
	border: 1px solid #DFE0EB !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.table-striped tbody:nth-of-type(2n+1) {
	background-color: rgba(0, 0, 0, 0.03);
}

.c-border {
	border-width: 1px !important;
}

.transform-178 {
	transform: rotate(178deg);
}

.text_sm_10 {
	font-size: 10px !important;
}

.br-50 {
	border-radius: 50% !important;
}

.cursor {
	cursor: pointer;
}

.blog-title {
	margin: auto;
	text-align: center;
	margin-top: 44px;
	width: 70%;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 17px;
	letter-spacing: 0.3px;
}

@media screen and (max-width: 600px) {

}

.el-image {
	height: 400px;
}

.margin-right-17 {
	margin-right: 17px !important;
}
</style>
