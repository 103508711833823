<template>
	<div>
		<div class="flex justify-center" v-if="blogPostPreviewImage !== ''">
			<el-image
				v-if="blogPostPreviewImage !== ''"
				style="width: 100%; height: auto"
				:src="blogPostPreviewImage"
			>
			</el-image>
		</div>
		<div class="space-x-4">
			<div class="blog-title">
				<p class="font-semibold text-gray-800 text-lg">
					{{ blogPostContent.title }}
				</p>
				<br />
				<small class="text-gray-400"
					>{{ $t("published") }}:
					{{ blogPostContent.published }}</small
				>
			</div>
		</div>
		<div class="flex flex-col mt-3">
			<div v-html="cleanMessage(blogPostContent.content)"></div>
		</div>
		<br />
		<p class="mt-2 mr-2" v-if="blogPostContent.category !== ''">
			Category:
			<span
				class="ml-2"
				v-html="renderArrays(blogPostContent.category)"
			></span>
		</p>
		<p class="mt-3" v-if="blogPostContent.tags">
			Tags: <span v-html="renderArrays(blogPostContent.tags)"></span>
		</p>
	</div>
</template>

<script>
export default {
	name: "blogPreviewCard",
	data: function () {
		return {
			blogPostPreviewImage: "",
			blogPostContent: {},
		};
	},
	methods: {
		cleanMessage(message) {
			return message;
		},
		renderArrays(data) {
			const result = [];
			let html = "";
			if (data) {
				if (data.length > 0) {
					data.map((v) => result.push(v.name));
					result.forEach((element) => {
						html += `<span class='px-2 text-gray-700 py-1 border rounded-full badge badge-white'>${element}</span>&nbsp;`;
					});
					return html;
				}
			}
		},
		getBase64File(file) {
			return new Promise((resolve, reject) => {
				var reader = new FileReader();
				reader.onload = (e) => {
					resolve(e.target.result);
				};
				reader.readAsDataURL(file);
			});
		},
	},
	beforeDestroy() {
		// window.localStorage.setItem('__blogPreview__', JSON.stringify({}))
		// window.localStorage.setItem('__blogPreviewImageP__', '')
	},
	mounted() {
		this.blogPostContent = JSON.parse(
			window.localStorage.getItem("__blogPreview__")
		);
		this.blogPostPreviewImage = window.localStorage.getItem(
			"__blogPreviewImageP__"
		);
	},
};
</script>

<style scoped></style>
